// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// Para uso do font customizado::
// . Escolhido e feito download do font a partir de https://fonts.google.com/
// . Criado diretorio assets/fonts e copiados os arquivos obtidos do download que serão usados
// . Incluídas as linhas @font-face abaixo e tb assinalamento do --ion-font-family no :root (abaixo)
// . No app.component.scss incluido assinalamento para a font-family geral (*)
@font-face {
  font-family: montserrat;
  src: url("../assets/fonts/Montserrat-VariableFont_wght.ttf") format('woff2 supports variations'),
    url("../assets/fonts/Montserrat-VariableFont_wght.ttf") format('woff2-variations');
}

:root {
  // Fonts
  --ion-font-family: 'montserrat';

  // =========== Cores =============
  // Azul
  --ion-color-primary: #092f8a;
  --ion-color-primary-rgb: 9, 47, 138;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5585a6;
  --ion-color-primary-tint: #338cd6;

  // usado em muitos casos --background:
  --ion-color-secondary: #f2f2f2;
  --ion-color-secondary-rgb: 242, 242, 242;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #dadada;
  --ion-color-secondary-tint: #f4f4f4;

  // --ion-color-tertiary: #d7dd35;
  --ion-color-tertiary: #ffa615;
  --ion-color-tertiary-rgb: 255, 166, 21;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #f78e34;
  --ion-color-tertiary-tint: #ffb921;

  --ion-color-success: #35e306;
  --ion-color-success-rgb: 53, 227, 6;
  --ion-color-success-contrast: #1c520c;
  --ion-color-success-contrast-rgb: 28, 82, 12;
  // --ion-color-success-shade é igual no tema cliente e no tema guia
  --ion-color-success-shade: #217cc7;
  --ion-color-success-tint: #7cf15c;

  --ion-color-warning: #ff8705;
  --ion-color-warning-rgb: 255, 135, 5;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e07704;
  --ion-color-warning-tint: #ff931e;

  --ion-color-danger: #f40724;
  --ion-color-danger-rgb: 244, 7, 36;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d70620;
  --ion-color-danger-tint: #f5203a;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444;

  --ion-color-medium: #ddcbca;
  --ion-color-medium-rgb: 221, 203, 202;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #c2b3b2;
  --ion-color-medium-tint: #e0d0cf;

  --ion-color-light: #f9cf00;
  --ion-color-light-rgb: 249, 207, 0;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #fff0df; // usado para fundo tela puxando para tertiary
  --ion-color-light-tint: #ffffc8;  // usado para fundo tela puxando tertiary um pouco mais forte

  // o ion-color-favorite sao cores que sao as mesmas no tema pricipal e no tema para o guia
  --ion-color-favorite: #092f8a;      // eh a cor principal do PasseioJa
  --ion-color-favorite-rgb: 9, 47, 138;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #e2e8f2; // usado o focus nos botoes - usando mesmo padrao ionic (entre azul clarinho e cinza)
  --ion-color-favorite-tint: #939393; // usada em textos acinzentados

  .ion-color-favorite {
    --ion-color-base: var(--ion-color-favorite);
    --ion-color-base-rgb: var(--ion-color-favorite-rgb);
    --ion-color-contrast: var(--ion-color-favorite-contrast);
    --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
    --ion-color-shade: var(--ion-color-favorite-shade);
    --ion-color-tint: var(--ion-color-favorite-tint);
  }

  .guiaTema {
    --ion-color-primary: #ffa615;
    --ion-color-primary-rgb: 255, 166, 21;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #f78e34;
    --ion-color-primary-tint: #ffb921;

    --ion-color-secondary: #f2f2f2;
    --ion-color-secondary-rgb: 242, 242, 242;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #dadada;
    --ion-color-secondary-tint: #f4f4f4;

    --ion-color-tertiary: #092f8a;
    --ion-color-tertiary-rgb: 9, 47, 138;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #1d5275;
    --ion-color-tertiary-tint: #338cd6;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #1c520c;
    --ion-color-success-contrast-rgb: 28, 82, 12;
      // --ion-color-success-shade é igual no tema cliente e no tema guia
    --ion-color-success-shade: #217cc7;
    --ion-color-success-tint: #7cf15c;

    --ion-color-warning: #ff8705;
    --ion-color-warning-rgb: 255, 135, 5;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e07704;
    --ion-color-warning-tint: #ff931e;

    --ion-color-danger: #f40724;
    --ion-color-danger-rgb: 244, 7, 36;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d70620;
    --ion-color-danger-tint: #f5203a;

    --ion-color-dark: #2f2f2f;
    --ion-color-dark-rgb: 47, 47, 47;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #292929;
    --ion-color-dark-tint: #444;

    --ion-color-medium: #ddcbca;
    --ion-color-medium-rgb: 221, 203, 202;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #c2b3b2;
    --ion-color-medium-tint: #e0d0cf;

    --ion-color-light: #f6d912;
    --ion-color-light-rgb: 246, 217, 18;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #fff0df; // usado para fundo tela puxando para tertiary
    --ion-color-light-tint: #b5c8f6;  // usado para fundo tela puxando tertiary um pouco mais forte

    // o ion-color-favorite sao cores que sao as mesmas no tema pricipal e no tema para o guia
    --ion-color-favorite: #092f8a;      // eh a cor principal do PasseioJa
    --ion-color-favorite-rgb: 9, 47, 138;
    --ion-color-favorite-contrast: #ffffff;
    --ion-color-favorite-contrast-rgb: 255, 255, 255;
    --ion-color-favorite-shade: #e2e8f2; // usado o focus nos botoes - usando mesmo padrao ionic (entre azul clarinho e cinza)
    --ion-color-favorite-tint: #939393; // usada em textos acinzentados

  }     // #a1b7ec

}

// foi mudado de dark para darks para nao usar o tema dark nunca
@media (prefers-color-scheme: darks) {
  /*
   * Dark Colors - ficou igual ao schema principal
   * -------------------------------------------
   */

  :root {
    --ion-color-primary: #092f8a;
    --ion-color-primary-rgb: 9, 47, 138;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #5585a6;
    --ion-color-primary-tint: #338cd6;

    // usado em muitos casos --background:
    --ion-color-secondary: #f2f2f2;
    --ion-color-secondary-rgb: 242, 242, 242;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #dadada;
    --ion-color-secondary-tint: #f4f4f4;

    // --ion-color-tertiary: #d7dd35;
    --ion-color-tertiary: #ffa615;
    --ion-color-tertiary-rgb: 255, 166, 21;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #f78e34;
    --ion-color-tertiary-tint: #ffb921;

    --ion-color-success: #35e306;
    --ion-color-success-rgb: 53, 227, 6;
    --ion-color-success-contrast: #1c520c;
    --ion-color-success-contrast-rgb: 28, 82, 12;
      // --ion-color-success-shade é igual no tema cliente e no tema guia
    --ion-color-success-shade: #217cc7;
    --ion-color-success-tint: #7cf15c;

    --ion-color-warning: #ff8705;
    --ion-color-warning-rgb: 255, 135, 5;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e07704;
    --ion-color-warning-tint: #ff931e;

    --ion-color-danger: #f40724;
    --ion-color-danger-rgb: 244, 7, 36;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d70620;
    --ion-color-danger-tint: #f5203a;

    --ion-color-dark: #2f2f2f;
    --ion-color-dark-rgb: 47, 47, 47;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #292929;
    --ion-color-dark-tint: #444;

    --ion-color-medium: #ddcbca;
    --ion-color-medium-rgb: 221, 203, 202;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #c2b3b2;
    --ion-color-medium-tint: #e0d0cf;

    --ion-color-light: #f9cf00;
    --ion-color-light-rgb: 249, 207, 0;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    // o ion-color-favorite sao cores que sao as mesmas no tema pricipal e no tema para o guia
    --ion-color-favorite: #092f8a;      // eh a cor principal do PasseioJa
    --ion-color-favorite-rgb: 9, 47, 138;
    --ion-color-favorite-contrast: #ffffff;
    --ion-color-favorite-contrast-rgb: 255, 255, 255;
    --ion-color-favorite-shade: #e2e8f2; // usado o focus nos botoes - usando mesmo padrao ionic (entre azul clarinho e cinza)
    --ion-color-favorite-tint: #939393; // usada em textos acinzentados

    .ion-color-favorite {
      --ion-color-base: var(--ion-color-favorite);
      --ion-color-base-rgb: var(--ion-color-favorite-rgb);
      --ion-color-contrast: var(--ion-color-favorite-contrast);
      --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
      --ion-color-shade: var(--ion-color-favorite-shade);
      --ion-color-tint: var(--ion-color-favorite-tint);
    }

  }

}

// body.dark pode ser usado para browsers antigos - foi repetido o tema principal
body.dark {
  --ion-color-primary: #092f8a;
  --ion-color-primary-rgb: 9, 47, 138;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5585a6;
  --ion-color-primary-tint: #338cd6;

  // usado em muitos casos --background:
  --ion-color-secondary: #f2f2f2;
  --ion-color-secondary-rgb: 242, 242, 242;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #dadada;
  --ion-color-secondary-tint: #f4f4f4;

  // --ion-color-tertiary: #d7dd35;
  --ion-color-tertiary: #ffa615;
  --ion-color-tertiary-rgb: 255, 166, 21;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #f78e34;
  --ion-color-tertiary-tint: #ffb921;

  --ion-color-success: #35e306;
  --ion-color-success-rgb: 53, 227, 6;
  --ion-color-success-contrast: #1c520c;
  --ion-color-success-contrast-rgb: 28, 82, 12;
  // --ion-color-success-shade é igual no tema cliente e no tema guia
  --ion-color-success-shade: #217cc7;
  --ion-color-success-tint: #7cf15c;

  --ion-color-warning: #ff8705;
  --ion-color-warning-rgb: 255, 135, 5;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e07704;
  --ion-color-warning-tint: #ff931e;

  --ion-color-danger: #f40724;
  --ion-color-danger-rgb: 244, 7, 36;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d70620;
  --ion-color-danger-tint: #f5203a;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444;

  --ion-color-medium: #ddcbca;
  --ion-color-medium-rgb: 221, 203, 202;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #c2b3b2;
  --ion-color-medium-tint: #e0d0cf;

  --ion-color-light: #f9cf00;
  --ion-color-light-rgb: 249, 207, 0;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // o ion-color-favorite sao cores que sao as mesmas no tema pricipal e no tema para o guia
  --ion-color-favorite: #092f8a;      // eh a cor principal do PasseioJa
  --ion-color-favorite-rgb: 9, 47, 138;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #e2e8f2; // usado o focus nos botoes - usando mesmo padrao ionic (entre azul clarinho e cinza)
  --ion-color-favorite-tint: #939393; // usada em textos acinzentados

  .ion-color-favorite {
    --ion-color-base: var(--ion-color-favorite);
    --ion-color-base-rgb: var(--ion-color-favorite-rgb);
    --ion-color-contrast: var(--ion-color-favorite-contrast);
    --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
    --ion-color-shade: var(--ion-color-favorite-shade);
    --ion-color-tint: var(--ion-color-favorite-tint);
  }

}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  // --ion-background-color: #000000;
  // --ion-background-color-rgb: 0, 0, 0;

  // --ion-text-color: #ffffff;
  // --ion-text-color-rgb: 255, 255, 255;

  // --ion-color-step-50: #0d0d0d;
  // --ion-color-step-100: #1a1a1a;
  // --ion-color-step-150: #262626;
  // --ion-color-step-200: #333333;
  // --ion-color-step-250: #404040;
  // --ion-color-step-300: #4d4d4d;
  // --ion-color-step-350: #595959;
  // --ion-color-step-400: #666666;
  // --ion-color-step-450: #737373;
  // --ion-color-step-500: #808080;
  // --ion-color-step-550: #8c8c8c;
  // --ion-color-step-600: #999999;
  // --ion-color-step-650: #a6a6a6;
  // --ion-color-step-700: #b3b3b3;
  // --ion-color-step-750: #bfbfbf;
  // --ion-color-step-800: #cccccc;
  // --ion-color-step-850: #d9d9d9;
  // --ion-color-step-900: #e6e6e6;
  // --ion-color-step-950: #f2f2f2;

  // --ion-item-background: #000000;

  // --ion-card-background: #1c1c1d;
}

// .ios body.dark ion-modal {
//   --ion-background-color: var(--ion-color-step-100);
//   --ion-toolbar-background: var(--ion-color-step-150);
//   --ion-toolbar-border-color: var(--ion-color-step-250);
//   --ion-item-background: var(--ion-color-step-150);
// }

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  // --ion-background-color: #121212;
  // --ion-background-color-rgb: 18, 18, 18;

  // --ion-text-color: #ffffff;
  // --ion-text-color-rgb: 255, 255, 255;

  // --ion-border-color: #222222;

  // --ion-color-step-50: #1e1e1e;
  // --ion-color-step-100: #2a2a2a;
  // --ion-color-step-150: #363636;
  // --ion-color-step-200: #414141;
  // --ion-color-step-250: #4d4d4d;
  // --ion-color-step-300: #595959;
  // --ion-color-step-350: #656565;
  // --ion-color-step-400: #717171;
  // --ion-color-step-450: #7d7d7d;
  // --ion-color-step-500: #898989;
  // --ion-color-step-550: #949494;
  // --ion-color-step-600: #a0a0a0;
  // --ion-color-step-650: #acacac;
  // --ion-color-step-700: #b8b8b8;
  // --ion-color-step-750: #c4c4c4;
  // --ion-color-step-800: #d0d0d0;
  // --ion-color-step-850: #dbdbdb;
  // --ion-color-step-900: #e7e7e7;
  // --ion-color-step-950: #f3f3f3;

  // --ion-item-background: #1e1e1e;

  // --ion-toolbar-background: #1f1f1f;

  // --ion-tab-bar-background: #1f1f1f;

  // --ion-card-background: #1e1e1e;
}

ion-title.title-large {
  --color: white;
}
