/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
// comentada linha abaixo para evitar dar muitos warnins shadow css
// e classes css de float nao sao usadas
// @import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// foi comentada a linha abaixo criada pelo ionic 8 - nao usamos o dark
// @import '@ionic/angular/css/palettes/dark.system.css';


// /* swiper slides.component.scss */
// @import 'swiper/swiper.min.css';
// @import 'swiper/modules/navigation.min.css';
// @import 'swiper/modules/pagination.min.css';

/* as linhas abaixo foram colocadas pq comecou a dar problema para abrir o popover no ion-select  */
/* parece que na versao ionic 6.5.2 estah resolvido, mas ainda estamos na 6.3.7 */
// atualizada a versao ionic/angular para 6.5.7 em jun/23 mas foi deixado como precaucao
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

// tamanho do icone do ion-radio - fixando para todo o APP - precisa nominar part="xxx" em cada ion-radio
ion-radio::part(container) {
  width: 15px;
  height: 15px;
}


// no ionic 8 a class hidden foi excluida - então isso substitui
[hidden] {
  display: none !important;
}

// ATENCAO para uso limites responsive nos scss (@media):
//  ==> quando usado no max-width eh sempre um numero a menos, ex.: 767
//  . min-width    0 a max-width  575px  ==> equivalente a size-xs
//  . min-width  576 a max-width  767px  ==> equivalente a size-sm
//  . min-width  768 a max-width  991px  ==> equivalente a size-md
//  . min-width  992 a max-width 1199px  ==> equivalente a size-lg
//  . min-width 1200                     ==> equivalente a size-xl

// cria variaveis sat, sar,... com o conteudo das respectivas safe-area dos devices iOS
// caso seja necessario usar no javascript usar os comandos abaixo:
//   let _temp = getComputedStyle(document.documentElement).getPropertyValue("--sat");
//   this.alertAppSrv.toast(_temp);
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

.glToastCss {
  border-radius: 5px;
}

ion-button {
  // nao faz zoom no iOS quando fizer duplo click nos botoes
  touch-action: manipulation;
  // faz com que o titulo do botao nao faça uppercase automaticamente
  text-transform: none;
}

.button {
  // nao faz zoom no iOS quando fizer duplo click nos botoes
  touch-action: manipulation;
}

.glCabec {
  width: 100%;
  // env(safe-area... acrescenta o espaco no cabec
  height: calc(env(safe-area-inset-top, 0px) + 50px);
  background: var(--ion-color-primary);

  @media (min-width:768px)  {
    margin-top: 70px!important;
  }

}

.glMarginTop {
  margin-top: 70px!important;
}

.glCabecModal {
  width: 100%;
  height: 50px;
  background: var(--ion-color-primary);
}

.glCabecComImagem {
  min-height: 150px !important;
  width: 100%;
  max-width: 500px !important;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

// padding abaixo eh para que no ios nao dê problema com parte da tela do iphone
// ja que estamos usando viewport-fit=cover no index.html
.glPaddingSafeArea {
  padding-top: env(safe-area-inset-top, 0px);
}

.glCabecSecondary {
  width: 100%;
  height: 50px;
  background: var(--ion-color-secondary-shade);
}

.glToolbarRodape {
  --background: var(--ion-color-primary);
}

.glBackButtonGrid {
  --color: var(--ion-color-tertiary) !important;
  // --background: var(--ion-color-primary)!important;
  border-color: var(--ion-color-tertiary-contrast) !important;
  z-index: 2;
  margin-right: 5px;
  margin-top: -2px;
}

.glBackButtonImagem {
  position: absolute;
  margin-left: 0px !important;
  margin-top: 40px !important;
  padding-bottom: 0px;
  height: 60px !important;
  width: 60px !important;
  color: var(--ion-color-light) !important;
  border-color: var(--ion-color-light-contrast) !important;
  z-index: 2;
}


.glTituloCabec {
  text-align: center;
  margin-top: 0px;
  width: 80%;
  margin-left: 10%;
  padding: calc(env(safe-area-inset-top, 10px)) 0px 0px 0px;
  // padding: calc(env(safe-area-inset-top, 0px) + 10px) 0px 0px 0px;
  color: var(--ion-color-primary-contrast);
}

.glTituloCabecSecondary {
  text-align: center;
  margin-top: 0px;
  padding: 10px 0px 0px 0px;
  color: var(--ion-color-primary-secondary);
}

.glTituloCabecGrid {
  text-align: center;
  margin-top: 0px;
  width: 80%;
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  color: var(--ion-color-primary-contrast);
}

.glToolbarHeaderDesktop {
  height: 50px !important;
  padding-bottom: 0px;
  background-color: var(--ion-color-primary);
  z-index: 2;
  position: relative;
}


.glBackButtonDesktop {
  position: absolute;
  margin-left: 0px !important;
  margin-top: 50px !important;
  color: var(--ion-color-tertiary) !important;
  border-color: var(--ion-color-tertiary-contrast) !important;
  z-index: 2;
}

.glTituloPaginaDesktop {
  position: absolute;
  color: white;
  margin-top: 15px;
  padding: 5px 0 5px -20px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  z-index: 3;
}

.glToolbarCabecDesktop_2 {
  height: 50px;
  --background: var(--ion-color-primary);
  padding-bottom: 0px;
  z-index: 2;
  position: relative;
  margin-top: 70px;
}

.glBackButtonDesktop_2 {
  position: absolute;
  padding: 5px !important;
  // padding-left: 10px !important;
  margin-top: -5px !important;
  color: var(--ion-color-tertiary) !important;
  border-color: var(--ion-color-tertiary-contrast) !important;
  z-index: 2;
}

.glTituloPaginaDesktop_2 {
  position: absolute;
  color: white;
  margin-top: -10px;
  padding: 5px 0 5px -20px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  z-index: 3;
}

// margem lateral em toda a row de uma página apenas quando tamanho tela maior 992px
.glRowMargemLateral {
  @media (min-width:992px) and (max-width:1399px) {
    padding-left: 3%;
    padding-right: 3%;
  }

  @media (min-width:1400px)  {
    padding-left: 7%;
    padding-right: 7%;
  }
}

.glJustificado {
  text-align: justify;
  text-justify: inter-character;
}

.glQuebraLinha {
  white-space: pre-line;
}

.glCorCinza {
  color: var(--ion-color-step-600, #666);
}

.modalClass {
  &::part(content) {
    height: 470px;
    max-height: 100%;
    width: 312px;
  }
}

.modalClassApp {
  &::part(content) {
    height: 560px;
    max-height: 100%;
    min-width: 300px;
    width: 100%;
    border-bottom: 2px solid var(--ion-color-primary-tint);
  }
}

.modalClassBrowser {
  &::part(content) {
    height: 670px !important;
    width: 670px;
    max-width: 670px;
    border: 2px solid var(--ion-color-primary-tint);
  }
}

.modalIntroApp {
  &::part(content) {
    height: 100%;
    max-height: 100%;
    margin-top: 0%;
    min-width: 300px;
    max-width: 100%;
    border-bottom: 2px solid var(--ion-color-primary-tint);
  }
}

.modalIntroBrowser {
  &::part(content) {
    height: 500px;
    max-height: 80%;
    width: 540px;
    max-width: 540px;
    border: 2px solid var(--ion-color-primary-tint);
  }
}

.modalClassAlert {
  &::part(content) {
    --width: 90% !important;
    --height: 60% !important;
    max-width: 670px;
    border: 2px solid var(--ion-color-primary-tint);
    border-radius: 5px;
  }
}

.modalFotosApp {
  &::part(content) {
    // height: 470px;
    max-height: 80%;
    min-width: 300px;
    width: 100%;
    border-bottom: 2px solid var(--ion-color-primary-tint);
  }
}

.modalFotosBrowser {
  &::part(content) {
    // height: 670px;
    max-height: 90%;
    min-height: 570px;
    width: 670px;
    border: 2px solid var(--ion-color-primary-tint);
  }
}

.modalClassHelpApp {
  &::part(content) {
    // max-height: 40%;
    width: 90%;
    // max-width: 266px;
    max-height: 61vw;
    border: 2px solid var(--ion-color-primary-tint);
    margin-right: 5% !important;
    top: 160px !important;
    position: absolute;
    border-radius: 10px;
  }
}

.modalClassHelp {
  &::part(content) {
    // max-height: 40%;
    width: 520px;
    height: 333px;
    // max-width: 266px;
    max-height: 61vw;
    border: 2px solid var(--ion-color-primary-tint);
    margin-left: auto;
    margin-right: auto;
    // margin-right: 5% !important;
    top: 160px !important;
    position: absolute;
    border-radius: 10px;
  }
}


.modalMenuDesktopPerfil {
  &::part(content) {
    max-height: 40%;
    width: 180px;
    border: 2px solid var(--ion-color-primary-tint);
    right: 60px !important;
    top: 60px !important;
    position: absolute;
    border-radius: 5px;
  }
}

.modalMenuDesktopAjuda {
  &::part(content) {
    max-height: 45%;
    width: 240px;
    border: 2px solid var(--ion-color-primary-tint);
    right: 10px !important;
    top: 60px !important;
    position: absolute;
    border-radius: 5px;
  }
}

.toolbar___sem_sombra {
  width: calc(100%);
  border-bottom: 1px solid var(--ion-color-secondary-shade);
  padding: 10 0 0 0;
  border-radius: 5px;
  float: left;
}

// este css eh para o ion-select customizado - vide combo origem no cad. usuario
// interfaceoptionsOrigem faz menção ao custom-popover-select
.custom-popover-select .popover-content {
  position: relative;
  top: 20px !important;
  left: 40px !important;
  --min-height: 200px !important;
}

.glFont48Thin {
  font-size: 48px;
  font-weight: 225;
}

.glFont40Thin {
  font-size: 40px;
  font-weight: 225;
}

.glFont32Thin {
  font-size: 32px;
  font-weight: 225;
}

.glFont24Bold {
  font-size: 24px;
  font-weight: 500;
}

.glFont20600 {
  // título de card
  font-size: 20px;
  font-weight: 600;
}

.glFont20Bold {
  // título de card
  font-size: 20px;
  font-weight: 500;
}

.glFont18Bold {
  // título de card
  font-size: 18px;
  font-weight: 500;
}

.glFont16Bold {
  // título de campo
  font-size: 16px;
  font-weight: 500;

}

.glFont16 {
  // descrições nos cards
  font-size: 16px;
  font-weight: 400;
}

.glFont15600 {
  // descrições nos cards
  font-size: 15px;
  font-weight: 600;
}

.glFont15Bold {
  // descrições nos cards
  font-size: 15px;
  font-weight: 500;
}

.glFont15 {
  // descrições nos cards
  font-size: 15px;
  font-weight: 400;
}

.glFont14Bold {
  // titulos botões pequenos
  font-size: 14px;
  font-weight: 500;
}

.glFont14 {
  // descrições no detalhamento
  font-size: 14px;
  font-weight: 400;
}

.glFont13600 {
  // itens (lista cidades)
  font-size: 13px;
  font-weight: 600;
}

.glFont13Bold {
  // itens (lista cidades)
  font-size: 13px;
  font-weight: 500;
}

.glFont13 {
  // observaçoes
  font-size: 13px;
  font-weight: 400;
}

.glFont12 {
  // observaçoes do APP
  font-size: 12px;
  font-weight: 400;
}

.glFont11_5 {
  // observaçoes do APP
  font-size: 11.5px;
  font-weight: 400;
}


.glCinzaDestaquePlusPlus {
  color: #222222; //
}

.glCinzaDestaquePlus {
  color: #333333; // rgb 51 51 51
}

.glCinzaDestaque {
  color: #444444; // rgb 68 68 68
}

.glCinzaNormal {
  color: #666666; // rgb 102 102 102
}

.glCinzaLeve {
  color: #888888; // rgb 136 136 136
}

.glSuccess {
  color: var(--ion-color-success);
}

// Para ocorrencias de itens horizontalmente fazendo wrap para demais linhas
.glGroupItens {
  display: inline-flex;
  float: left;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}

.glItemWrapper {
  min-width: fit-content;
  height: fit-content;
}

.glLineHeightBrowser {
  line-height: 2;
}
.glLineHeightApp {
  line-height: 1.8;
}
